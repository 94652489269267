<template>
  <div v-if="isHaveToPathRight">
    <router-view></router-view>
  </div>
  <Page404 v-else-if="isShowContent" />
</template>

<script setup>
import Page404 from '@/pages/common/404'
import useMenuRights from '@/hooks/useMenuRights.js'

const { isShowContent, isHaveToPathRight } = useMenuRights()
// console.log('🚀 ~ isHaveToPathRight:', isHaveToPathRight)
</script>
