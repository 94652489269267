import { onMounted, computed, watch, ref } from 'vue'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import { useStore } from 'vuex'
import { handleTokenFail } from '@/utils/request'
import { ElMessage } from 'element-plus'

// 这几个详情页不需要验证 权限
export const noNeedRightsRoutes = [
  // 详情页
  '/web/system/doctor/doctormgr/detail',
  '/web/system/doctor/doctorregistermgr/detail',
  '/web/system/doctororder/ordermgr/detail',
  '/web/system/serviceorder/ordermgr/detail',
  '/web/system/goods/goodsmgr/detail',
  '/web/system/uitemplatedata/uitemplatedatamgr/edit',
  '/web/system/active/activemgr/edit',
]

/**
 *
 * @param {*} url
 * @param {*} [allMenu]
 * @returns {boolean}
 */
export function useHaveRight(curPath, allMenu) {
  let allRights = []
  if (allMenu) {
    allRights = allMenu
  } else {
    allRights = store.state?.user?.allMenu || []
  }

  let isHave = false

  if (noNeedRightsRoutes.includes(curPath.value)) {
    isHave = true
  } else {
    for (let i = 0; i < allRights.length; i++) {
      if (curPath.value == allRights[i].menuUrl) {
        isHave = true
        break
      }
    }
  }

  return isHave
}

export default function useMenuRights() {
  const route = useRoute()
  const store = useStore()

  const curPath = ref(route.path)
  onBeforeRouteUpdate((to, from) => {
    curPath.value = to.path
  })

  // user
  const allMenu = computed(() => store.state.user.allMenu)
  const menuTree = computed(() => store.state.user.menuTree)
  // 权限
  const isShowContent = computed(() => store.getters['user/isShowContent'])
  const isAllMenuEmpty = computed(() => store.getters['user/isAllMenuEmpty'])
  const isHaveToPathRight = computed(() => useHaveRight(curPath, allMenu.value)) // 当前 单个页面权限

  watch(
    isAllMenuEmpty,
    (v) => {
      if (v) {
        ElMessage.warning('没有菜单权限')
        handleTokenFail()
      }
    },
    { immediate: true }
  )

  return {
    // 权限
    isShowContent,
    isHaveToPathRight,
  }
}
